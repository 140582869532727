import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { WatchNowOfferFragmentDoc } from './WatchNowOffer.fragment';
export type PopularTitleGraphql_Movie_Fragment = {
	__typename: 'Movie';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	freeOffersCount: number;
	seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	content: {
		__typename?: 'MovieContent';
		title: string;
		fullPath: string;
		posterUrl?: string | null;
		isReleased: boolean;
		runtime?: number | null;
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbVotes?: number | null;
			imdbScore?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
			jwRating?: number | null;
		};
		interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
		credits: Array<{ __typename?: 'Credit'; name: string; personId: number }>;
		genres: Array<{ __typename?: 'Genre'; translation: string; shortName: string }>;
	};
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
};

export type PopularTitleGraphql_Show_Fragment = {
	__typename: 'Show';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	freeOffersCount: number;
	tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number; progress: number };
	content: {
		__typename?: 'ShowContent';
		title: string;
		fullPath: string;
		posterUrl?: string | null;
		isReleased: boolean;
		runtime?: number | null;
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbVotes?: number | null;
			imdbScore?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
			jwRating?: number | null;
		};
		interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
		credits: Array<{ __typename?: 'Credit'; name: string; personId: number }>;
		genres: Array<{ __typename?: 'Genre'; translation: string; shortName: string }>;
	};
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
};

export type PopularTitleGraphqlFragment = PopularTitleGraphql_Movie_Fragment | PopularTitleGraphql_Show_Fragment;

export const PopularTitleGraphqlFragmentDoc = gql`
	fragment PopularTitleGraphql on MovieOrShow {
		__typename
		id
		objectId
		objectType
		content(country: $country, language: $language) {
			title
			fullPath
			scoring {
				imdbVotes
				imdbScore
				tmdbPopularity
				tmdbScore
				tomatoMeter
				certifiedFresh
				jwRating
			}
			interactions {
				votesNumber
			}
			dailymotionClips: clips(providers: [DAILYMOTION]) {
				sourceUrl
				externalId
				provider
			}
			posterUrl(profile: $profile, format: $format)
			... on MovieOrShowOrSeasonContent {
				backdrops(profile: $backdropProfile, format: $format) {
					backdropUrl
				}
			}
			isReleased
			credits(role: $creditsRole) {
				name
				personId
			}
			runtime
			genres {
				translation(language: $language)
				shortName
			}
		}
		likelistEntry {
			createdAt
		}
		dislikelistEntry {
			createdAt
		}
		watchlistEntryV2 {
			createdAt
		}
		customlistEntries {
			createdAt
		}
		freeOffersCount: offerCount(country: $country, platform: WEB, filter: { monetizationTypes: [FREE, ADS] })
		watchNowOffer(country: $country, platform: WEB, filter: $watchNowFilter) {
			...WatchNowOffer
		}
		... on Movie {
			seenlistEntry {
				createdAt
			}
		}
		... on Show {
			tvShowTrackingEntry {
				createdAt
			}
			seenState(country: $country) {
				seenEpisodeCount
				progress
			}
		}
	}
	${WatchNowOfferFragmentDoc}
`;
